// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-js": () => import("../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-getting-started-js": () => import("../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

